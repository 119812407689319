import React, { Fragment } from 'react';

const About = () => {
  return (
    <Fragment>
      <h2>About this App</h2>
      <p>App to Search Github Users</p>
      <p>Version 1.0.0</p>
    </Fragment>
  );
};

export default About;
